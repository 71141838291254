import { Component, OnInit } from '@angular/core';
import { UpdateDemande } from 'src/app/models/update-demande';
import { DemandsService } from 'src/app/services/demands.service';

@Component({
  selector: 'app-bac',
  templateUrl: './bac.component.html',
  styleUrls: ['./bac.component.css']
})
export class BacComponent implements OnInit {

  constructor(private demand:DemandsService) { }

  ngOnInit(): void {
    this.noRetire()
  }
  //---------non retirés
  bac = "Retrait définitif du BAC";
  tot = 0;
  allDemands:any;
  retraitBac = [];
  noRetire(){
    this.retraitBac = [];
    this.demand.noRetir().subscribe(
      res => {
        this.allDemands = res;
        this.tot = Object.keys(this.allDemands).length;
        let k = 0;
        for(let i=0;i<this.tot;i++){
          if(this.allDemands[i].documentType.documentType == this.bac){
            this.retraitBac.push(this.allDemands[i]);
            k++;
          }
        }//for
        this.tot = k;
        //console.log(this.retraitBac);
        },
      err =>console.error(err)
    )
  }

  //--------------------update------------------------
upDem = new UpdateDemande();
getDemd(id:number,dateRecup:string,retrait:string,comment:string){
  this.upDem.id = id;
  this.upDem.dateRecup = dateRecup;
  this.upDem.retrait = retrait;
  this.upDem.comment = comment;
  //console.log(this.upDem);
}

DemandeUpdate(){
  this.demand.updateDem(this.upDem).subscribe(
    () => this.noRetire(),
    err => console.error(err)

  )
}
//----------------------------------------------------------
}
