<!--
<nav class="navbar navbar-expand-lg bg-info navbar-dark menu">
  <div class="container-fluid">
    <a class="navbar-brand">FLSHM</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a *ngIf="isSuperAdmin" class="nav-link active" routerLink="/add-user">Users</a>
        <a class="nav-link active" routerLink="/cp">Doc non traités</a>
        <a class="nav-link active" routerLink="/no-retir">Doc non retirés</a>
        <a class="nav-link active" routerLink="/search">Recherche</a>

      </div>
    </div>
  </div>
</nav>
-->
<div class="row" style="margin-top: 10px; text-align: right;">
  <div class="col-9"></div>
  <div class="col-3" style="font-size: 13pt; color: rgb(84, 7, 119);">
    <i style="margin-right: 5px; color: rgb(84, 7, 119);" class="fas fa-user fa-2x"></i>{{nameUser|uppercase}}
    <a class="btn" (click)="logout()"><i style="color: brown; margin-left: 10px;"  class="fas fa-sign-out-alt fa-2x"></i></a>
  </div>

</div>
<div class="row" style="margin-top: 5px;">
  <button *ngIf="isSuperAdmin" class="btn btn-danger ombre col-sm-2 p-4" routerLink="/add-user">{{"Users"|uppercase}}</button>
<button *ngIf="isSuperAdmin || isAdmin" class="btn btn-success ombre col-sm-3 p-4" routerLink="/cp">{{"Documents non traités"|uppercase}}</button>
<button *ngIf="isSuperAdmin || isAdmin || isUser"  class="btn btn-info col-sm-3 ombre"  routerLink="/no-retir">{{"Documents non retirés"|uppercase}}</button>
<button *ngIf="isSuperAdmin || isAdmin || isUserBac" class="btn btn-warning col-sm-3 ombre"  routerLink="/bac-no-retir">{{"Retrait du Bac"|uppercase}}</button>
<button class="btn btn-primary col-sm-1 ombre"  routerLink="/search"><i class="fas fa-search fa-3x"></i></button>
</div>
