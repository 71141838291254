import { Router } from '@angular/router';
import { Student } from './../../models/student';
import { StudentService } from './../../services/student.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-student',
  templateUrl: './auth-student.component.html',
  styleUrls: ['./auth-student.component.css']
})
export class AuthStudentComponent implements OnInit {
apogee = "";
cin = "";
  constructor(private std:StudentService, private router:Router) { }

  ngOnInit(): void {
  }
student:any;
errorAuth = false;
  authentification(){
    this.std.studentAuth(this.apogee,this.cin).subscribe(
      data => {
        this.student = data;
        //console.log(this.student);
        this.std.currentStuden.apogee = this.student.apogee;
        this.std.currentStuden.cin = this.student.cin;
        this.std.currentStuden.nom = this.student.nom;
        this.std.currentStuden.prenom = this.student.prenom;
        /*localStorage.setItem("apogee",this.student.apogee);
        localStorage.setItem("cin",this.student.cin);
        localStorage.setItem("nom",this.student.nom);
        localStorage.setItem("prenom",this.student.prenom);
        */
        this.std.studentSignIn();
        this.router.navigate(['/espace-etudiant'])
      },

      err => {console.error(err); this.errorAuth = true;},
      () => console.log("ok")



    );
  }

}
