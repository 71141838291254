import { Router } from '@angular/router';
import { LoginServiceService } from './../../services/login-service.service';
import { LocalizedString } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cp-menu',
  templateUrl: './cp-menu.component.html',
  styleUrls: ['./cp-menu.component.css']
})
export class CpMenuComponent implements OnInit {

  constructor(private aut:LoginServiceService, private route:Router) { }
isSuperAdmin = false;
isAdmin = false;
isUserBac = false;
isUser = false;
nameUser:string;
  ngOnInit(): void {
    //this.nameUser = localStorage.getItem("nom");
    this.nameUser = this.aut.usernam;
    //const roles = localStorage.getItem("roles");
    const roles = this.aut.roles;
    if (roles.includes("Super-Admin")){
      this.isSuperAdmin = true;
    }
    if (roles.includes("Admin")){
      this.isAdmin = true;
    }
    if (roles.includes("user-bac")){
      this.isUserBac= true;
    }
    if (roles.includes("user")){
      this.isUser = true;
    }
  }
  //---------------------------------------
logout(){
  this.aut.signOut();
  this.route.navigate(['flshm-documents-control']);
}
  //-----------------------------------------

}
