import { StudentService } from './../services/student.service';
import { Injectable } from '@angular/core';
import {  CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EspaceEtudiantGuard implements CanActivate {
  constructor(private std:StudentService, private router:Router){}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.std.studentIsLoggedIn){
      return true;
    }else{
      this.router.navigate(['/home'])
      return false;
    }

  }

}
