import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostService {
  //myhost = "http://localhost:8080/";
  myhost = "http://196.200.169.149:8080/";
  constructor() { }
}
