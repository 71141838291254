import { Student } from './../../models/student';
import { Router } from '@angular/router';
import { StudentService } from './../../services/student.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-espace-etudiant',
  templateUrl: './espace-etudiant.component.html',
  styleUrls: ['./espace-etudiant.component.css']
})
export class EspaceEtudiantComponent implements OnInit {
  student = new Student();
  langAr: Boolean;
  langFr: Boolean;
  constructor(private std:StudentService, private router:Router) { }

  ngOnInit(): void {
   /* this.student.apogee = localStorage.getItem("apogee");
    this.student.cin = localStorage.getItem("cin");
    this.student.nom = localStorage.getItem("nom");
    this.student.prenom = localStorage.getItem("prenom");
    */
   this.student = this.std.currentStuden;
    this.studentDemands();
    this.langAr = this.std.langAr;
    this.langFr = this.std.langFr;


  }
  //-------------------------------------------------
  allDemande:any;

  studentDemands(){
    this.std.demands(this.student.apogee).subscribe(
      data => {
        this.allDemande = data;
        //console.log(this.allDemande);
      },
      err => {console.error(err);},
      ()=> console.log("ok")
    )
  }
  //----------------------
  passerDemande(){
    this.router.navigate(['/passer-demande']);
  }
  //-------------------------------------------------------
  //---------Choix de la langue----------
  choixAr(){
    this.langAr = true;
    this.langFr = false;
    this.std.langAr = true;
    this.std.langFr = false;
  }
  choixFr(){
    this.langAr = false;
    this.langFr = true;
    this.std.langAr = false;
    this.std.langFr = true;
  }
  //-------------------logout----------
  signOut(){
    this.std.studentSignOut();
  }
  //------------------refresh---------------
  refreshpage(){
    this.studentDemands();
    //console.log("refresh: ",this.std.studentIsLoggedIn);
  }
  //-------------------------------------------------------


}
