<div class="container">

  <div class="row">
    <div class="col-8">
      <div >
        <table class="carte">
          <tr><td colspan="2"><i class="fas fa-id-card fa-3x"></i> </td></tr>
          <tr><td>Nom & Prénom:</td><td>{{student.prenom}} {{student.nom}}</td></tr>
          <tr><td>Apogée:</td><td>{{student.apogee}}</td></tr>
          <tr><td>CNI:</td><td>{{student.cin}}</td></tr>
        </table>
      </div>
    </div>
    <div class="col-2"><a routerLink="/espace-etudiant" alt="Mon espace"><i class="fas fa-3x fa-arrow-alt-circle-left"></i></a></div>
    <div class="col-2"><a href="" style="color: brown;" (click)="signOut()" ><i class="fas fa-sign-out-alt fa-3x"></i></a></div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>

<div class="row" *ngIf="afficherButtonConfirm">
  <div *ngIf="langAr" class="col-sm-12">
    <button dir="rtl" class="btn btn-danger col-sm-12 col-md-6 offset-md-3" style="font-weight: bold;" (click)="demander()"> إضغط لتأكيد طلب {{doc.typeArabe}}</button>
  </div>
  <div *ngIf="langFr" class="col-sm-12">
    <button dir="rtl" class="btn btn-danger col-sm-12 col-md-6 offset-md-3" style="font-weight: bold;" (click)="demander()">Cliquez pour confirmer la demande de:  {{doc.type}}</button>
  </div>
  <div class="col-sm-12" style="margin-top: 10px;">
    <button class="btn btn-info col-sm-12 col-md-6 offset-md-3" routerLink="/espace-etudiant">الرجوع إلى صفحتي <i class="fas fa-2x fa-arrow-alt-circle-left"></i>Retour à mon espace </button>
  </div>
</div>
<div class="row text-center" *ngIf="dmd" style="margin-top: 10px;">

    <div class="alert alert-info " style="font-weight: bolder;" role="alert">
     <p *ngIf="langAr">لقد تم إيداع طلبك الخاص ب {{doc.typeArabe}}</p>
     <p *ngIf="langFr">Votre demande est bien enregistrée:  {{doc.type}}</p>
     <div *ngIf="langAr" class="col-sm-12" style="margin-top: 10px;">
      <button class="btn btn-info" routerLink="/passer-demande">طلب وثيقة أخرى</button>
    </div>
    <div *ngIf="langFr" class="col-sm-12" style="margin-top: 10px;">
      <button class="btn btn-info" routerLink="/passer-demande">Demande d'un autre document</button>
    </div>
    <div class="col-sm-12" style="margin-top: 10px;">
      <button class="btn btn-info" routerLink="/espace-etudiant"><i class="fas fa-2x fa-arrow-alt-circle-left"></i></button>
    </div>
    </div>


</div>

<div class="row" *ngIf="erreureDemande" style="margin-top: 10px;">
  <div class="col-sm-12 alert alert-danger text-center" style="font-weight: bolder;" >
    <p *ngIf="langAr" dir="rtl">لا يمكنك طلب {{doc.typeArabe}} </p>
    <p *ngIf="langFr">Vous ne pouvez pas demander {{doc.type}} </p>
    <p dir="rtl" *ngIf="langAr">لقد قمت بطلب {{doc.typeArabe}} سابقا </p>
    <p *ngIf="langFr">Vous avez déjà demandé: {{doc.type}}</p>
   <p *ngIf="langAr"> <button class="btn btn-primary" routerLink="/passer-demande">طلب وثيقة أخرى</button></p>
   <p *ngIf="langFr"> <button class="btn btn-primary" routerLink="/passer-demande">Damandez un autre document</button></p>
    <p><button class="btn btn-info" routerLink="/espace-etudiant"><i class="fas fa-arrow-alt-circle-left"></i></button></p>
</div>
</div>


  </div>
