import { AddRoles } from './../../models/add-roles';
import { UserService } from './../../services/user.service';
import { User } from './../../models/user';
import { Component, OnInit, Host } from '@angular/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(private us:UserService) { }

  ngOnInit(): void {
    this.all();
  }
  //-----------------add user------------------
  user = new User();
  adduser(){
    this.us.addNewUser(this.user).subscribe(
      () => this.all(),
      err => console.error(err)
    )
  }
  //----------Add roles----------------------
  supa = false;
  admin = false;
  usrBac = false;
  usr = false;
  userRoles = new AddRoles();
  //----------------------------------------------------------------
  addRoles(){
this.userRoles.username = this.username;
this.userRoles.roles = [];
if(this.supa){
  this.userRoles.roles.push('Super-Admin');
}
if(this.admin){
  this.userRoles.roles.push('Admin');
}
if(this.usr){
  this.userRoles.roles.push('user');
}
if(this.usrBac){
  this.userRoles.roles.push('user-bac');
}
//console.log(this.userRoles);
this.us.addRoles(this.userRoles).subscribe(
  () => {
    this.all();
    this.supa = false;
    this.admin = false;
    this.usrBac = false;
    this.usr = false;
  },
  err => console.error(err)
)

  }
  //------------------All users--------------------
  users = Array<User>();
  all(){
    this.us.allUsers().subscribe(
      data => this.users = data
    )
  }
  //------------------------------------------------------
  //------------------Delete users--------------------
  username = "";
  getUsername(nameofuser:string){
    this.username = nameofuser;
  }
  del(){
    this.us.delUser(this.username).subscribe(
      () => this.all(),
      err => console.error(err)
    )
      }
  //------------------------------------------------------

}
