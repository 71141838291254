import { Student } from './../models/student';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from '../models/login';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {
  headers: HttpHeaders;

  constructor(private _http:HttpClient, private hostService:HostService) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
   }

   host = this.hostService.myhost;

  _url = this.host + "api/login";

  _url2=this.host +"api/demande/liste?apogee=17507896";
  getStudent(){
    return this._http.get(this._url2)
  }

  flmLogin(user:Login){
    //return this._http.post(this._url,{params:{"username":user.username,"password":user.password}},{ headers: this.headers })
    const body = new HttpParams()
          .set('username',user.username)
          .set('password',user.password);
    return this._http.post(this._url,body.toString(),{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')

    })
  }
  //---------------------------------------------------
//----------------------------------------------
isLoggedIn: Boolean = false;
//------pour stocker les infos user----
usernam = "";
token = "";
roles = [];
//--------------------------------------
signIn(){
     this.isLoggedIn = true;
     localStorage.setItem('isLoggedIn', String(this.isLoggedIn));
}
//---------------------signOut-----------------------
signOut(){
     this.isLoggedIn = false;
     localStorage.setItem('isLoggedIn', String(this.isLoggedIn));
}
  //---------------------------------------------------

  urlStudent = this.host +"api/student/add";
  setSetudent(student:Student){
    return this._http.post(this.urlStudent,student,{ headers: this.headers });
  }
}
