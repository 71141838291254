import { Student } from './../../models/student';
import { LoginServiceService } from './../../services/login-service.service';

import { Component, OnInit } from '@angular/core';
import { Login } from 'src/app/models/login';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loginService:LoginServiceService,
              private router:Router) { }

  ngOnInit(): void {
  }
userLog = new Login();
reponse   : any;
token     : any;
decodedHeader: any;
utilisateur = "";
roles:any;
helper = new JwtHelperService();
getTokens(){
    this.loginService.flmLogin(this.userLog).subscribe(
    data => {
      //console.log(data);
      this.reponse = data;
      this.token = this.reponse.access_token;
      this.decodedHeader = this.helper.decodeToken(this.token);//jwt
      //console.log(this.decodedHeader);
      this.utilisateur = this.decodedHeader.sub;
      this.roles = this.decodedHeader.roles;
     // console.log(this.utilisateur);
     // console.log("ayaaaaaaaaaaaaaaaaaad; ",this.roles[0]);
      //console.log("roooles:",this.roles.includes('Admin'));


      if(this.utilisateur != ""){
        this.loginService.usernam = this.utilisateur;
        this.loginService.token = this.token;
        this.loginService.roles = this.roles;
        /*
        localStorage.setItem('token',this.token);
        localStorage.setItem('nom',this.utilisateur);
        localStorage.setItem('roles',this.roles);
        */
        this.loginService.signIn();

        if(this.roles.includes('user-bac') && !this.roles.includes('Admin') && !this.roles.includes('Super-Admin') )
        {
          this.router.navigate(['/bac-no-retir']);
        }
        if(this.roles.includes('user') && !this.roles.includes('Admin') && !this.roles.includes('Super-Admin'))
        {
          this.router.navigate(['/no-retir']);
        }
        if(this.roles.includes('Admin') || this.roles.includes('Super-Admin'))
        {
          this.router.navigate(['/cp']);
        }

      }
    },
    err => console.error("error login user  ",this.userLog.username,"err = ",err),
    ()=>console.log("ok")
  )
 }
 //---------------------------------------------------------

 getAllStudent(){
  this.loginService.getStudent().subscribe(
    //data => console.log(data),
    ()=>console.log("FLSHM")

  )
}
//-------------------------
student = new Student()
addStudent(){
  this.student.apogee ="45780";
  this.student.cin = "GF874";
  this.student.nom = "Sarakova";
  this.student.prenom = "vignin";
  this.loginService.setSetudent(this.student).subscribe(
   // data => console.log(data),
    err => console.error("error insert ",this.student,"err = ",err),
    ()=>console.log("ok")


  )
}
//-------------------------------------------------
}
