import { Component, OnInit } from '@angular/core';
import { DemandsService } from 'src/app/services/demands.service';

@Component({
  selector: 'app-student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.css']
})
export class StudentSearchComponent implements OnInit {

  constructor(private demand:DemandsService) { }
cin = "";
apogee = "";
  ngOnInit(): void {
  }

  demandeError = false;
  nbrDemd: number;
  //---------------Demandes d'un étudiant par apogée
  allDemande:any;
  studentDemands(){
    this.demand.demands(this.apogee).subscribe(
      data => {
        this.allDemande = data;
        this.nbrDemd = data.toString().length;
        this.demandeError = false;
      },
      err => {console.error(err);
        this.allDemande =null;
        this.demandeError = true},
      ()=> console.log("ok")
    );

  }

  //---------------Demandes d'un étudiant par apogée

  studentDemandsbyCin(){
    this.demand.demandsbycin(this.cin).subscribe(
      data => {
        this.allDemande = data;
        this.nbrDemd = data.toString().length;
        this.demandeError = false;

      },
      err => {console.error(err);
        this.allDemande =null;
        this.demandeError = true},
      ()=> console.log("ok")
    );

  }


}
