<app-cp-menu></app-cp-menu>
<div class="container-fluid">
<h1 class="text-danger">Users</h1>
<div class="row">
  <div class="col-lg-6 offset-lg-3">
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input type="text"  class="form-control" id="staticEmail" [(ngModel)]="user.name" name="name">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="user.username" name="username" id="inputPassword">
      </div>
    </div>

    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-10">
        <input type="password" class="form-control" [(ngModel)]="user.password" name="pwd" id="inputPassword">
      </div>
    </div>



    <div class="mb-3 row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
       <button class="btn btn-primary col-12" (click)="adduser()">Add user</button>
      </div>
    </div>

  </div>
</div>

<!----------------Users manage---------------->
<div class="row">
  <div class="col-lg-10 offset-lg-1">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Username</th>
          <th scope="col">Roles</th>
          <th scope="col">Add roles</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let us of users">
          <th scope="row">{{us.name}}</th>
          <td>{{us.username}}</td>
          <td>
            <ul>
              <li *ngFor="let role of us.roles">{{role.role}}</li>
            </ul>
          </td>
          <td><button class="btn btn-success" (click)="getUsername(us.username)" data-bs-toggle="modal" data-bs-target="#modalRoles"><i class="fas fa-user-secret"></i></button></td>
          <td><button class="btn btn-danger" (click)="getUsername(us.username)" data-bs-toggle="modal" data-bs-target="#modalSuppr"><i class="fas fa-trash-alt"></i></button></td>
        </tr>
        </tbody>
    </table>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalSuppr" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> Suppression</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >

        <p dir="ltr">Voulez-vous vraiment supprimer {{username}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="del()" data-bs-dismiss="modal" >  Oui </button>
        <button type="button" class="btn btn-danger but" data-bs-dismiss="modal"> Non </button>
      </div>
    </div>
  </div>
</div>
<!-- fin Modal -->

<!-- Modal add roles-->
<div class="modal fade" id="modalRoles" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> Add Roles</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
          <form>
            <div class="mb-3 form-check">
              <input type="checkbox" name="sup" [(ngModel)]="supa" value="Super-Admin" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">Super-Admin</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" name="adm" [(ngModel)]="admin" value="Admin" class="form-check-input" id="exampleCheck2">
              <label class="form-check-label" for="exampleCheck1">Admin</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" name="usr" [(ngModel)]="usr" value="user" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">User</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" name="usrbac" [(ngModel)]="usrBac" value="user" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">User-Bac</label>
            </div>
          </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addRoles()" data-bs-dismiss="modal" >  Add roles </button>
        <button type="button" class="btn btn-danger but" data-bs-dismiss="modal"> Non </button>
      </div>
    </div>
  </div>
</div>
<!-- fin Modal add roles -->
</div>
