<div class="container-fluid">
<h1 class="text-info">{{"Administration FLSHM"|uppercase}}</h1>
<div class="row">
  <div class="col-lg-6 offset-lg-3">
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-2 col-form-label">Login</label>
      <div class="col-sm-10">
        <input type="text"  class="form-control" id="staticEmail" [(ngModel)]="userLog.username" name="usernam">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-10">
        <input type="password" class="form-control" [(ngModel)]="userLog.password" name="pwd" id="inputPassword">
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
       <button class="btn btn-success col-12"  (click)="getTokens()">Login</button>
      </div>
    </div>

  </div>
</div>
</div>
