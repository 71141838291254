
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <img src="/assets/img/docFlm.jpg" class="img img-fluid" alt="">
    </div>
  </div>

  <router-outlet></router-outlet>
<app-footer></app-footer>
</div>

