import { BacComponent } from './adm/bac/bac.component';
import { StudentSearchComponent } from './adm/student-search/student-search.component';
import { DocNonReitireComponent } from './adm/doc-non-reitire/doc-non-reitire.component';
import { AddUserComponent } from './adm/add-user/add-user.component';
import { AddStudentComponent } from './site/add-student/add-student.component';
import { ConfirmationDemandeComponent } from './site/confirmation-demande/confirmation-demande.component';
import { PasserDemandeComponent } from './site/passer-demande/passer-demande.component';
import { EspaceEtudiantGuard } from './guards/espace-etudiant.guard';
import { EspaceEtudiantComponent } from './site/espace-etudiant/espace-etudiant.component';
import { CpGuard } from './guards/cp.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CpComponent } from './adm/cp/cp.component';
import { LoginComponent } from './adm/login/login.component';
import { AuthStudentComponent } from './site/auth-student/auth-student.component';

const routes: Routes = [
  {path:'flshm-documents-control',component:LoginComponent},
  {path:'cp',component:CpComponent,canActivate:[CpGuard]},
  {path:'add-user',component:AddUserComponent,canActivate:[CpGuard]},
  {path:'no-retir',component:DocNonReitireComponent,canActivate:[CpGuard]},
  {path:'bac-no-retir',component:BacComponent,canActivate:[CpGuard]},
  {path:'search',component:StudentSearchComponent,canActivate:[CpGuard]},
  {path:'espace-etudiant',component:EspaceEtudiantComponent,canActivate:[EspaceEtudiantGuard]},
  {path:'passer-demande',component:PasserDemandeComponent,canActivate:[EspaceEtudiantGuard]},
  {path:'confirmation-demande',component:ConfirmationDemandeComponent,canActivate:[EspaceEtudiantGuard]},
  {path:'home',component:AuthStudentComponent},
  //{path:'inscription', component:AddStudentComponent},
  {path:'',component:AuthStudentComponent},
  {path:'',redirectTo:'/home',pathMatch:'full'},
  {path:'**',redirectTo:'/home'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
