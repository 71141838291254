import { StudentService } from './../../services/student.service';
import { Student } from './../../models/student';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {

  student = new Student();
  constructor(private std:StudentService) { }

  ngOnInit(): void {
  }

  added = false;
  addedError = false;
  inscription(){
this.std.addStudent(this.student).subscribe(
  data => {this.added = true; this.addedError = false; },
  err => {this.added = false; this.addedError = true; },

)
  }

}
