
<div class="container">
<div class="row">
  <div class="col-10">
    <div >
      <table class="carte">
        <tr><td colspan="2"><i class="fas fa-id-card fa-3x"></i> </td></tr>
        <tr><td>Nom & Prénom:</td><td>{{student.prenom}} {{student.nom}}</td></tr>
        <tr><td>Apogée:</td><td>{{student.apogee}}</td></tr>
        <tr><td>CNI:</td><td>{{student.cin}}</td></tr>
      </table>
    </div>
  </div>

  <div class="col-2">
    <a href="" style="color: brown;" (click)="signOut()" ><i class="fas fa-sign-out-alt fa-3x"></i></a>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>
<div class="row" dir="rtl">
  <div class="col-12">
    <h2 dir="ltr" style="text-align: right;" class="text-primary">Choix de la langue- إختيار اللغة</h2>
  </div>
  <button  (click)="choixAr()" mat-fab color="primary" aria-label="">
    عربي
  </button>
  <button (click)="choixFr()" mat-fab color="primary" style="margin-right: 10px;">
    Français
  </button>

</div>
<div class="row">
  <div class="col-12">
    <hr>
  </div>
</div>

<div class="row" style="margin-bottom: 10px;">
    <button *ngIf="langFr" class="btn col-6 offset-3 btn2 p-4" style="font-size: 18pt;" (click)="passerDemande()">Demander un document</button>
    <button *ngIf="langAr" class="btn col-6 offset-3 btn1 p-4" style="font-size: 18pt;" (click)="passerDemande()">طلب وثيقة</button>
</div>


<div class="row" *ngIf="allDemande!=null">
  <div class="col-12 text-center text-primary" style="font-size: 16pt; font-weight: bold;">
    <p *ngIf="langAr">الطلبات السابقة <a class="btn"  style="color: rgb(6, 155, 142);" (click)="refreshpage()" ><i class="fas fa-sync fa-2x"></i></a></p>
    <p *ngIf="langFr"><a class="btn" style="color: rgb(6, 155, 142);" (click)="refreshpage()" ><i class="fas fa-sync fa-2x"></i></a>Mes anciennes demandes</p>
  </div>
  <div class="col-md-6" *ngFor="let dmd of allDemande; let i=index">
    <div class="card">
      <div class="card-header text-center attestation">
        <p *ngIf="langFr">{{dmd.documentType.documentType}}</p>
        <p *ngIf="langAr">{{dmd.documentType.documentTypeArabe}}</p>
      </div>
      <!------------------------Affichage francais-->
      <ul class="list-group list-group-flush" *ngIf="langFr">
        <li class="list-group-item dt">Date de la demande: {{dmd.date}} </li>
        <li class="list-group-item ret" *ngIf="dmd.dateDeRecuperation!=null">Retrait à partir de: {{dmd.dateDeRecuperation}}</li>
        <li class="list-group-item ret" *ngIf="dmd.dateDeRecuperation==null">Retrait à partir de: en cours...</li>
        <li class="list-group-item dt" *ngIf="dmd.retire!=null">Retiré le: {{dmd.retire}}</li>
        <li class="list-group-item cmt">Commentaire: {{dmd.commentaire}}</li>
      </ul>
      <!------------------------Affichage Arabe-->
      <ul class="list-group list-group-flush" *ngIf="langAr" dir="rtl">
        <li class="list-group-item dt"> تاريخ إيداع الطلب :{{dmd.date}} </li>
        <li class="list-group-item ret" *ngIf="dmd.dateDeRecuperation!=null"> السحب إنطلاقا من :{{dmd.dateDeRecuperation}}</li>
        <li class="list-group-item ret" *ngIf="dmd.dateDeRecuperation==null">  السحب إنطلاقا من: في طور الإنجاز</li>
        <li class="list-group-item dt" *ngIf="dmd.retire!=null">وثيقة مسحوبة بتاريخ: {{dmd.retire}}</li>
        <li class="list-group-item cmt">ملاحظات :{{dmd.commentaire}}  </li>
      </ul>
    </div>
  </div>
</div>


</div>
