import { UpdateDemande } from './../../models/update-demande';
import { DemandsService } from './../../services/demands.service';
import { Component, OnInit } from '@angular/core';
import { LoginServiceService } from 'src/app/services/login-service.service';

@Component({
  selector: 'app-no-procced-demands',
  templateUrl: './no-procced-demands.component.html',
  styleUrls: ['./no-procced-demands.component.css']
})
export class NoProccedDemandsComponent implements OnInit {

  constructor(private aut:LoginServiceService, private demand:DemandsService) { }
  isSuperAdmin = false;//for delete demand
  ngOnInit(): void {
    const roles =this.aut.roles;
    if (roles.includes("Super-Admin")){
      this.isSuperAdmin = true;
    }
    this.noProcessed();
  }
  //--------------All no processed demands-------
  allDemands:any;
  tot=0;
  noProcessed(){
    this.demand.noProcessed().subscribe(
      res => {
        this.allDemands = res;
        this.tot = Object.keys(this.allDemands).length;
        //console.log(res);
      },
      err =>console.error(err)
    )
  }
//--------------------update------------------------
upDem = new UpdateDemande();
getDemd(id:number,dateRecup:string,retrait:string,comment:string){
  this.upDem.id = id;
  this.upDem.dateRecup = dateRecup;
  this.upDem.retrait = retrait;
  this.upDem.comment = comment;
  //console.log(this.upDem);
}

DemandeUpdate(){
  this.demand.updateDem(this.upDem).subscribe(
    () => this.noProcessed(),
    err => console.error(err)

  )
}
//---------------------Delete demande--------------------
del_id:number;
getIdtodelete(id){
   this.del_id = id;
}

supprimerDemande(){
  this.demand.deleteDemande(this.del_id).subscribe(
    ()=>{
      this.noProcessed();
    },
    err => console.error(err),

  )
}
//----------------------------------------------------------

}
