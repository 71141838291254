import { Router } from '@angular/router';
import { DocType } from './../../models/doc-type';
import { Component, OnInit } from '@angular/core';
import { Student } from 'src/app/models/student';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-passer-demande',
  templateUrl: './passer-demande.component.html',
  styleUrls: ['./passer-demande.component.css']
})
export class PasserDemandeComponent implements OnInit {
  student = new Student();
  constructor(private std:StudentService,private router:Router) { }
allDocs:any;
  ngOnInit(): void {
    /*this.student.apogee = localStorage.getItem("apogee");
    this.student.cin = localStorage.getItem("cin");
    this.student.nom = localStorage.getItem("nom");
    this.student.prenom = localStorage.getItem("prenom");
    */
   this.student = this.std.currentStuden;
    this.std.allDocs().subscribe(
      data => {
        this.allDocs = data;
        
      },
      //err => console.error("DocsTypes not loaded!"),
      () => console.log("ok")
    );

  }
  //---------------------------------------
  doc = new DocType();
  res:any;
  getDoc(id:number){
    this.std.unDoc(id).subscribe(
      data => {
           this.res = data;
           this.doc.id = this.res.id;
           this.doc.type = this.res.documentType;
           this.doc.typeArabe = this.res.documentTypeArabe;
           this.std.documentChoisi = this.doc;
           //console.log(this.res);

      }
    )

  }
  //-------------------------------------
  goToConfirmation(){
    this.router.navigate(['/confirmation-demande'])
  }

//-------------------logout----------
signOut(){
  this.std.studentSignOut();
}

  //-----------------------------------------------

}
