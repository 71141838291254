import { LoginServiceService } from './login-service.service';
import { AddRoles } from './../models/add-roles';
import { User } from './../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  headers: HttpHeaders;
  constructor(private _http:HttpClient,
              private loginService:LoginServiceService,
              private hostService:HostService) {
    //const token = localStorage.getItem('token');
    const token = loginService.token ;
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }

   host = this.hostService.myhost;
   //----------------all users-----------------
  urlAll = this.host + "api/flmusers/allusers";
  allUsers(){
    return this._http.get<User[]>(this.urlAll,{ headers: this.headers })
  }

  //----------------Add user-----------------
  urlAdd = this.host + "api/flmusers/add";
  addNewUser(user:User){
    return this._http.post(this.urlAdd,user,{ headers: this.headers })
  }
  //------------------Add Roles-------------------
  urlRol = this.host + "api/flmusers/addroles"
  addRoles(userRoles:AddRoles){
    return this._http.post(this.urlRol,userRoles,{ headers: this.headers })
  }
  //----------------delete user-----------------
  urldel = this.host + "api/flmusers/del/";
  delUser(username:string){
    return this._http.delete(this.urldel+username,{ headers: this.headers })
  }



}
