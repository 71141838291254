import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './adm/login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CpComponent } from './adm/cp/cp.component';
import { AuthStudentComponent } from './site/auth-student/auth-student.component';
import { EspaceEtudiantComponent } from './site/espace-etudiant/espace-etudiant.component';
import { PasserDemandeComponent } from './site/passer-demande/passer-demande.component';
import { ConfirmationDemandeComponent } from './site/confirmation-demande/confirmation-demande.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { AddStudentComponent } from './site/add-student/add-student.component';
import { AddUserComponent } from './adm/add-user/add-user.component';
import { CpMenuComponent } from './adm/cp-menu/cp-menu.component';
import { NoProccedDemandsComponent } from './adm/no-procced-demands/no-procced-demands.component';
import { DocNonReitireComponent } from './adm/doc-non-reitire/doc-non-reitire.component';
import { StudentSearchComponent } from './adm/student-search/student-search.component';
import { FooterComponent } from './site/footer/footer.component';
import { BacComponent } from './adm/bac/bac.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CpComponent,
    AuthStudentComponent,
    EspaceEtudiantComponent,
    PasserDemandeComponent,
    ConfirmationDemandeComponent,
    AddStudentComponent,
    AddUserComponent,
    CpMenuComponent,
    NoProccedDemandsComponent,
    DocNonReitireComponent,
    StudentSearchComponent,
    FooterComponent,
    BacComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
