import { HostService } from './host.service';
import { LoginServiceService } from 'src/app/services/login-service.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateDemande } from '../models/update-demande';

@Injectable({
  providedIn: 'root'
})
export class DemandsService {

  headers: HttpHeaders;
  constructor(private _http:HttpClient,
              private loginService:LoginServiceService,
              private hostService:HostService) {
    //const token = localStorage.getItem('token');
    const token = loginService.token;
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
   host = this.hostService.myhost;
   //--------------No processed demands----------
  url1 = this.host + "api/demande/demandsnontraites";
  noProcessed(){
    return this._http.get(this.url1,{headers:this.headers});
  }
  //----------No retire------------------
  url2 = this.host + "api/demande/demandsnonretire";
  noRetir(){
    return this._http.get(this.url2,{headers:this.headers});
  }
  //------------------------Update demand----
  url3 = this.host +"api/demande/update";
updateDem(upDemand:UpdateDemande){
  return this._http.put(this.url3,upDemand,{headers:this.headers});
}
//---------------------Demandes d'un etudiant apo--------------
urlDemand = this.host+"api/demande/liste?apogee=";
demands(apo:string){
  return this._http.get(this.urlDemand+apo);
}

//---------------------Demandes d'un etudiant cin--------------
urlDemandCin = this.host+"api/demande/demandsbycin?cin=";
demandsbycin(cin:string){
  return this._http.get(this.urlDemandCin+cin);
}
//---------------delete demande-----------------
urlDelete = this.host +"api/demande/deletedemand?id=";
deleteDemande(id:number){
return this._http.delete(this.urlDelete+id,{headers:this.headers});
}


}
