<h1 class="text-danger">Inscription - Etudiant(e)</h1>
<div class="row">
  <div class="col-lg-6 offset-lg-3">
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-2 col-form-label">Apogée</label>
      <div class="col-sm-10">
        <input type="text"  class="form-control" id="staticEmail" [(ngModel)]="student.apogee" name="apo">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">CIN</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="student.cin" name="cin" id="inputPassword">
      </div>
    </div>

    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Nom</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="student.nom" name="nom" id="inputPassword">
      </div>
    </div>

    <div class="mb-3 row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Prénom</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" [(ngModel)]="student.prenom" name="prenom" id="inputPassword">
      </div>
    </div>

    <div class="mb-3 row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
       <button class="btn btn-primary col-12" (click)="inscription()">Entrer</button>
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-lg-6 offset-lg-3">
    <div *ngIf="added" class="alert alert-success" role="alert">
      Inscription réussie! <a href="">Entre dans votre espace</a>
    </div>

    <div *ngIf="addedError" class="alert alert-danger" role="alert">
      Vous êtes déjà inscrit(e)! <a href="">Entre dans votre espace</a>
    </div>
  </div>
</div>



