<app-cp-menu></app-cp-menu>

<div class="row" style="margin-top: 10px;">
  <div class="col-6">
    <input type="text" name="cin" class="form-control" placeholder="CNI" [(ngModel)]="cin">
  </div>
  <div class="col-6">
    <button (click)="studentDemandsbyCin()" class="btn btn-success">Recherche par CNI</button>
  </div>
  </div>

  <div class="row" style="margin-top: 10px;">
  <div class="col-6">
    <input type="text" name="apo" class="form-control" placeholder="Apogée" [(ngModel)]="apogee">
  </div>
  <div class="col-6">
    <button class="btn btn-success" (click)="studentDemands()">Recherche par Apogée</button>
  </div>
</div>

<div class="row" style="margin-top: 10px;">
  <div class="col-md-6" *ngFor="let dmd of allDemande; let i=index">
    <div class="card">
      <div class="card-header text-center attestation">
        <p>{{dmd.documentType.documentType}}</p>
      </div>
      <!------------------------Affichage francais-->
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Date de la demande: {{dmd.date}} </li>
        <li class="list-group-item">Retrait à partir de: {{dmd.dateDeRecuperation}}</li>
        <li class="list-group-item">Retiré le: {{dmd.retire}}</li>
        <li class="list-group-item">Commentaire: {{dmd.commentaire}}</li>
      </ul>

    </div>
  </div>

</div>
<div class="row">
  <div *ngIf="nbrDemd==0" class="alert alert-danger" role="alert">
    Pas de demandes
  </div>
  <div  *ngIf="demandeError" class="alert alert-danger text-center" role="alert">
    Erreur dans cin ou apogée
  </div>

</div>
