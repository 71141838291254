
<h1 class="text-primary">DEMANDES NON {{"traitées"| uppercase}} ({{tot}} {{"demandes"|uppercase}})</h1>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Apogée</th>
      <th scope="col">Nom & Prénom</th>
      <th scope="col">Demande</th>
      <th scope="col">Date demande</th>
      <th scope="col">Valider</th>
      <th *ngIf="isSuperAdmin" scope="col">Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dm of allDemands">
      <th scope="row">{{dm.student.apogee}}</th>
      <td>{{dm.student.nom}}  {{dm.student.prenom}}</td>
      <td>{{dm.documentType.documentType}}</td>
      <td>{{dm.date}}</td>
      <td><button class="btn btn-success" data-bs-toggle="modal"  data-bs-target="#modalUpdateDemande" (click)="getDemd(dm.id,dm.dateRecup,dm.retire,dm.commentaire)"><i class="fas fa-edit"></i></button></td>
      <td *ngIf="isSuperAdmin"><button class="btn btn-danger" (click)="getIdtodelete(dm.id)" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="fas fa-trash-alt"></i></button></td>
    </tr>
  </tbody>
</table>

<!-- Modal update demand-->
<div class="modal fade" id="modalUpdateDemande" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"> Demande</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Récupération à partir de:</label>
              <input type="date" name="dt" class="form-control" id="exampleFormControlInput1" [(ngModel)]="upDem.dateRecup">
            </div>
           <!-- <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Retrait le:</label>
              <input type="date" name="dtret" class="form-control" id="exampleFormControlInput1" [(ngModel)]="upDem.retrait">
            </div> -->
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Commentaire</label>
              <input type="text" name="cmt" class="form-control" id="exampleFormControlInput1" [(ngModel)]="upDem.comment">
            </div>

          </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="DemandeUpdate()" >  Valider </button>
        <button type="button" class="btn btn-danger but" data-bs-dismiss="modal"> Annuler </button>
      </div>
    </div>
  </div>
</div>
<!-- fin Modal update demand -->

<!-- delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="color: rgb(5, 71, 172); font-size: 14pt; "> Suppression</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
        <p dir="ltr">Voulez-vous vraiment supprimer cette demande?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger but" data-bs-dismiss="modal"> Non </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="supprimerDemande()"> Oui </button>
      </div>
    </div>
  </div>
</div>
<!-- fin delete Modal -->
