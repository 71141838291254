import { LoginServiceService } from './../services/login-service.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CpGuard implements CanActivate {
  constructor(private auth:LoginServiceService, private router:Router){}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.auth.isLoggedIn){
       // console.log('guard:',this.auth.isLoggedIn);
        return true;
      }else{
       // console.log('guard:',this.auth.isLoggedIn);
        this.router.navigate(['/login']);
        return false;
      }
  }

}
