<div class="container">
  <div class="row">

    <div class="col-sm-6 bord">
      <h1 class="text-info text-center">Authentification - Etudiant(e)</h1>
  <div class="row">
    <div class="col-lg-12">
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Apogée</label>
        <div class="col-sm-10">
          <input type="text"  class="form-control" id="staticEmail" [(ngModel)]="apogee" name="apo" placeholder="أبوجي">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="inputPassword" class="col-sm-2 col-form-label">CIN</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" [(ngModel)]="cin" name="cin" id="inputPassword" placeholder="رقم البطاقة الوطنية">
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
         <button class="btn btn-primary col-12" (click)="authentification()">Entrer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
  <div class="row" *ngIf="errorAuth">
    <h3  class="alert alert-danger">Erreur dans Apogée ou CIN</h3>
    <h3 dir="rtl"  class="alert alert-danger">رقم أبوجي أو رقم البطاقة الوطنية غير صحيح</h3>
  </div>
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
     <!-- <div class="row">
      <div class="col-6" ><a routerLink='/inscription'>S'inscrire</a></div>
      <div class="col-6" routerLink='/inscription'><a routerLink='/inscription'>تسجيل</a></div>
    </div>-->
    </div>
  </div>

    </div>
    <div class="col-sm-6 bord txtarab" style="padding-top: 10px;" dir="rtl">
      تخبر إدارة كلية الآداب والعلوم الإنسانية بالمحمدية جميع طلبتها أنه في إطار تجويد الخدمات المقدمة لهم، عملت على وضع منصة لطلب الوثائق وستتم العملية على الشكل التالي :
      <ol>
        <li>يطلب الطالب (ة) الوثائق المرغوب فيها عبر المنصة، وعندما ستنجز سيتم تحديد تاريخ سحبها دائما عبر المنصة.</li>
        <li>كل وثيقة تطلب مرة واحدة خلال المسار الدراسي للطالب (ة) باستثناء شهادة التسجيل التي تجدد كل سنة.</li>
        <li>لا تسلم نسخة ثانية من أي وثيقة في حالة ضياعها إلا بطلب من المعني بالأمر مصحوب بشهادة الضياع مسلمة من الأمن الوطني أو الدرك الملكي. </li>
        <li>لا تسلم الوثائق إلا للمعني (ة) بالأمر بعد الإدلاء بوثيقة أصلية لإثبات الهوية أو بوكالة أصلية.</li>
      </ol>
    </div>
  </div>


</div>
