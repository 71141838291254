import { Component, OnInit } from '@angular/core';
import { DemandeDoc } from 'src/app/models/demande-doc';
import { DocType } from 'src/app/models/doc-type';
import { Student } from 'src/app/models/student';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-confirmation-demande',
  templateUrl: './confirmation-demande.component.html',
  styleUrls: ['./confirmation-demande.component.css']
})
export class ConfirmationDemandeComponent implements OnInit {

  constructor(private std:StudentService) { }
  student = new Student();
  doc = new DocType();
  studentDemande = new DemandeDoc();
  langAr:boolean;
  langFr:boolean;
  ngOnInit(): void {
    /*this.student.apogee = localStorage.getItem("apogee");
    this.student.cin = localStorage.getItem("cin");
    this.student.nom = localStorage.getItem("nom");
    this.student.prenom = localStorage.getItem("prenom");
    */
   this.student = this.std.currentStuden
    this.doc = this.std.documentChoisi;
    this.studentDemande.apogee = this.student.apogee;
    this.studentDemande.documentId = this.std.documentChoisi.id;

    this.langAr = this.std.langAr;
    this.langFr = this.std.langFr;
    //console.log(this.langAr);


  }
  //----------------------------------------------------------------

  dmd:any;2
  erreureDemande = false;
  afficherButtonConfirm = true;
  demander(){
    this.std.setDemande(this.studentDemande).subscribe(
      data => {
        this.dmd = data;
        this.afficherButtonConfirm = false;
       // console.log(data);
      },
      err => {
        console.error(err);
        this.erreureDemande = true;
        this.afficherButtonConfirm = false;
      }
    )
  }
  //-------------------logout----------
  signOut(){
    this.std.studentSignOut();
  }
  //----------------------------------------------------

}
