<div class="container">

  <div class="row">
    <div class="col-8">
      <div >
        <table class="carte">
          <tr><td colspan="2"><i class="fas fa-id-card fa-3x"></i> </td></tr>
          <tr><td>Nom & Prénom:</td><td>{{student.prenom}} {{student.nom}}</td></tr>
          <tr><td>Apogée:</td><td>{{student.apogee}}</td></tr>
          <tr><td>CNI:</td><td>{{student.cin}}</td></tr>
        </table>
      </div>
    </div>
    <div class="col-2"><a routerLink="/espace-etudiant" alt="Mon espace"><i class="fas fa-3x fa-arrow-alt-circle-left"></i></a></div>
    <div class="col-2"><a href="" style="color: brown;" (click)="signOut()" ><i class="fas fa-sign-out-alt fa-3x"></i></a></div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr>
    </div>
  </div>
<div class="row">
  <div class="col-sm-12 att text-center" *ngFor="let doc of allDocs">
<!-- Button trigger modal -->
<button [hidden]="doc.documentType == 'Attestation de réussite semestre 3' || doc.documentType == 'Attestation de réussite semestre 4' || doc.documentType == 'Attestation de réussite semestre 5' || doc.documentType == 'Attestation de réussite semestre 6' || doc.documentType == 'Retrait définitif du BAC' || doc.documentType == 'Diplôme licence' || doc.documentType == 'Attestation de réussite licence'" type="button" (click)="getDoc(doc.id)" class="btn btn-primary col-sm-12 col-md-5" data-bs-toggle="modal" data-bs-target="#exampleModalDOCFLSHM">
  {{doc.documentType}} - {{doc.documentTypeArabe}}
</button>
  </div>
</div>



<!-- fin Button trigger modal -->
<!-- Modal -->
<div class="modal fade" id="exampleModalDOCFLSHM" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" style="color: rgb(5, 71, 172); font-size: 14pt; "> {{doc.type}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" >
        <p dir="rtl">هل تريد  فعلا طلب <span style="color: rgb(35, 4, 92); font-weight: bold;"> {{doc.typeArabe}}؟</span></p>
        <p dir="ltr">Voulez-vous vraiment demander<span style="color: rgb(35, 4, 92); font-weight: bold;"> {{doc.type}}</span> ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger but" data-bs-dismiss="modal">لا - Non </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="goToConfirmation()"> نعم - Oui </button>
      </div>
    </div>
  </div>
</div>
<!-- fin Modal -->
<!-- fin container-->
</div>
