import { Student } from './../models/student';
import { DemandeDoc } from './../models/demande-doc';
import { DocType } from './../models/doc-type';
import { HttpClient } from '@angular/common/http';
import { Host, Injectable } from '@angular/core';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private _http:HttpClient, private hostService:HostService) {

   }
   host = this.hostService.myhost;
   currentStuden = new Student(); //pour stocker l'etudiant authentifié
   langAr = true;
   langFr = false;
   documentChoisi = new DocType();//document choisi
   urlAuth =this.host +"api/student/authentification";
   studentAuth(apogee:string, cin:string){
     return this._http.post(this.urlAuth,{apogee,cin});
   }

   studentIsLoggedIn: Boolean = false;
studentSignIn(){
     this.studentIsLoggedIn = true;
     localStorage.setItem('studentIsLoggedIn', String(this.studentIsLoggedIn));
}
//---------------------signOut-----------------------
studentSignOut(){
     this.studentIsLoggedIn = false;
     localStorage.setItem('studentIsLoggedIn', String(this.studentIsLoggedIn));
}
//---------------------Demandes d'un etudiant--------------
urlDemand = this.host+"api/demande/liste?apogee=";
demands(apo:string){
  return this._http.get(this.urlDemand+apo);
}
//--------------Un documents type-------------------
urldoc = this.host+"api/demande/doc/";
unDoc(id:number){
  return this._http.get(this.urldoc+id);
}
//--------------Documents types-------------------
urlDocs = this.host+"api/demande/alldocs";
allDocs(){
  return this._http.get(this.urlDocs);
}
//--------------------Demander le document----------
urlStudentDemande = this.host + "api/demande/add";
setDemande(demande:DemandeDoc){
  return this._http.post(this.urlStudentDemande,demande);
}
//----------------Ajouter nouveau etudiant(e)---------
urlAdd = this.host +"api/student/add";
addStudent(student:Student){
  return this._http.post(this.urlAdd,student);
}



   //**************************************************** */
}
